import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Girl Empowerment",
  "author": "Christina",
  "date": "2020-03-11T07:59:13.000Z",
  "categories": ["empowerment", "Personal-Professional Growth"],
  "slug": "girl-empowerment",
  "draft": false,
  "meta_title": "Girl Empowerment",
  "cover": "../../images/wp_blog_images/img.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I was a Girl Scout for one year when I was an adolescent. I loved it. I wish I had the opportunity to join earlier in my life and continue on. When three of my daughters started Kindergarten, I signed them up. They loved it at first, but looking back now I forced them to stay past their enjoyment point. This is why I did not sign up my youngest daughter until she asked. I honestly never expected her to ask because Girl Scouts had stopped being the thing to do.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1236px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.5%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAC4jAAAuIwF4pT92AAADB0lEQVQozxXFXUxbVQAH8P8liFtwznRguaf33nPuOZfrbWlvoUCtfAzdgl3Y7LYIpt0Wi8SoCcZEH3zBBz/CQBbsNEaybGY6OrAfdIPBsmiWaEJitqiJRh80expgRJDgukwwMcfs4ZcfYFUHFevhkKvbMThwBKWtah1r3rmbhT01rMmkvLXeWxuxvLUR0w7EgoDfImqz8NEW+xHSKAzayilrFgZtF5RFIbGolZE5fxeZ4raSeXMh0QFZ8vXLgnpFFrWzUmIHfpI+zP6Vw9zmBOb/ft3zog18PyBw9cSQGg/DIgacnV4IShuwifFvVjAyeFsZrl9VRockruO3YfHQVpb8vDHJXuqbn0HV9K0jyC3/UHHhVw25pc2Kz6Sm5Pe1o5i8YTQ5FQ+OH1S0tsAOTlkQd/DBzR8r36pew9jgGk5NbPA3PP+MB7B9kVzZmBLtk9P9UPLLTyG/cguF3xdRWBpGcQIoJdowk5xHKYXKT48CUkLoNIgyMrOreH9kHad61zG2so4PW//7DmT7IlkpT9EheR67/PnFfhRWv1Lyy++isHQOk/IBzLydRKnvF8yk4phNNVWePeI3be5CYqrmD4xm/sTYJ6uVJ59bwsc996a9x7ey5NzdrPaOnKxKv5Y9eRi58gtdmY+qkLs9ii/WjivTl4+imHgPxdQZlJKvVmR700ajHYHEJXEPp8NbON0mccYd6Wwh8prHkZd9fjlHwnLOG04n/LX4UgaxcOdxLJRdXP23Zc/z3V5cP/YYrp2I4tsBHV+nwSkLYRDR0CuPdkfTtV3us94nAlHdbyc6dGfgaSN0bJ8R7uvUQoEGv0Ni3a7WcSBQF082+DoPRVgg4Kj7QyE13hjW2hscbpoOZ8wFPj/8so7qCLH0AzrVe0yDxg2NPaMRdkhVWZQQFuM67bRUr20RtUv4SG/9nt2aMGjMIsaTVp2+V/iMvZyyHmHQMFBK9TRjlyoEdy2DNQnKIoLSFus+Ri2LUltQJjjjHk7NCKdmGzd5DaeMc8pczpj//sKgNqdM/A8HQ/qTt1njVQAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "img",
        "title": "img",
        "src": "/static/8ff40cc483840912884f3ebfdb3708d6/cd78c/img.png",
        "srcSet": ["/static/8ff40cc483840912884f3ebfdb3708d6/72799/img.png 320w", "/static/8ff40cc483840912884f3ebfdb3708d6/6af66/img.png 640w", "/static/8ff40cc483840912884f3ebfdb3708d6/cd78c/img.png 1236w"],
        "sizes": "(max-width: 1236px) 100vw, 1236px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "225px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.33333333333331%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAMBBQIEBv/EABYBAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABsCoVs9IUppCMZitgQH//xAAdEAABBAIDAAAAAAAAAAAAAAACAAEDERATEiJB/9oACAEBAAEFArFBKJJitTBrYH77aUpci92Mnz//xAAVEQEBAAAAAAAAAAAAAAAAAAASIP/aAAgBAwEBPwEx/8QAFhEAAwAAAAAAAAAAAAAAAAAAERIg/9oACAECAQE/AXMf/8QAGhAAAwADAQAAAAAAAAAAAAAAAAEhECAxYf/aAAgBAQAGPwLuIxHhEWk1/8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITFBEFFxgbH/2gAIAQEAAT8hp6++Yw0md3AFgk2Ai/k+dTYl0p9stbQVjEw3wOJU/9oADAMBAAIAAwAAABCf0AP/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QAG7EGf/EABYRAQEBAAAAAAAAAAAAAAAAABEAEP/aAAgBAgEBPxBaL//EABwQAQADAQEBAQEAAAAAAAAAAAEAESExQVGR8P/aAAgBAQABPxAJaJWqc+wgAegqa5jxgCQRRRYC6YI13+yZyvR6fsb16m8CuEDsVq77sK1traIlW3rbCeYD5P/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "45688686 10161027924625297 3665246531159588864 n 225x300",
          "title": "45688686 10161027924625297 3665246531159588864 n 225x300",
          "src": "/static/be9acb9d69059deaaea6fb12a21a21ab/863e1/45688686_10161027924625297_3665246531159588864_n-225x300.jpg",
          "srcSet": ["/static/be9acb9d69059deaaea6fb12a21a21ab/863e1/45688686_10161027924625297_3665246531159588864_n-225x300.jpg 225w"],
          "sizes": "(max-width: 225px) 100vw, 225px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`My Charlie-girl asked me about Girl Scouts at the beginning of 3rd grade. At first, I thought it was just another thing she was interested in for a day. She asked for a 2nd and 3rd time. I finally gave in and applied for her to join a troop. I was skeptical at the first meeting. Girl Scouts had become unrecognizable to me throughout the years. The troops we were previously a part of put so much emphasis on earning badges and selling cookies, and they camped indoors. `}</p>
    <p>{`At the first meeting, I realized I was the only seasoned Girl Scout parent. The troop leader needed a co-leader as a requirement. There is a lot of training involved in being a leader and I had taken all the training, to include camp training while six months pregnant. I reluctantly raised my hand. It was the best decision I could have made. Not only did I enjoy being the co-leader in this troop, but I gained a cherished friend. `}</p>
    <p>{`Charlie was a member of her first Girl Scout troop for a year and a half until I moved us to Texas. I watched my daughter embrace friendships, discover a love of “real” camping, and grow as a scholar, entrepreneur, and leader. `}</p>
    <p>{`Being a part of this troop reminded me that nothing in life is one size fits all and to not judge based on past experiences. Girl Scouts, especially this troop, was a perfect fit for my daughter and myself. `}</p>
    <p>{`When we moved to Texas, one of my priorities was to find a troop for Charlie. We were pleasantly surprised by the warm welcome from the new troop and the great fit it was proving to be. This troop also embraced entrepreneurship, learning, and camping. We almost changed troops when we moved 45 minutes away, but the universe aligned, convincing us the long drive twice a month was worth it. `}</p>
    <p>{`The biggest lesson from my Girl Scouts experience spanning over 28 years is you cannot judge an organization based on a small group of individuals. Girl Scouts, as a whole, represents girl empowerment. There has been a lot of misinformation and distortion about this organization in the news and through conversation. `}</p>
    <p>{`Cookies sales are not meant to be about funding a Disney trip. Cookies sales are an opportunity to learn about entrepreneurship and help fund service projects. Girl Scouts only allowing girls in the troops is not meant to bash the opposite sex. It is an opportunity to create connections over commonalities and provide a safe space for girls to express themselves. Girl Scouts badge work is not about how many badges you can collect. Badge work allows girls to explore different subjects, often STEM-related. `}</p>
    <p>{`I am grateful that my daughters have had the opportunity to be in Girl Scouts. I believe this organization’s mission has been a part of a foundation for future success for my children.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      